function WhatAreYouWaitingFor() {
    return (
        <div className="whatAreYouWaiting">
            <div className="story">
                <h1 className="pink">What are you waiting for</h1>
                <div className="mission-text">
                    Are you ready to start chatting right now? There's no need to wait for a match—jump right in and engage in conversations that go beyond the surface level.<br/>
                    Dive into discussions that get straight to the <b className="pink">heart</b> of the matter, allowing you to truly connect and get to know each other on a deeper level.

                </div>
            </div>
            <div className="container">
                <div className="box"><img alt="dating" src="/img/dating-app.png"/>
                    <span className="whatare">Date & Have Fun.</span>
                </div>
                <div className="box"><img alt="dating" src="/img/dating.png"/>
                    <span className="whatare">Chat with other people.</span></div>
                <div className="box"><img alt="dating" src="/img/dating2.png"/>
                    <span className="whatare">Find Meaningful Connections.</span></div>
            </div>
        </div>
    )
}

export default WhatAreYouWaitingFor;