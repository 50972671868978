function Menu() {
    return (
        <header>
            <div className="menu roboto-thin">
                <span><a href="#home">Home</a></span>
                <span> | </span>
                <span><a href="#mission">Our Mission</a></span>
                <span> | </span>
                <span><a href="#howitworks">How It Works</a></span>
                <span> | </span>
                <span><a href="/privacy">Privacy</a></span>
                <span> | </span>
                <span><a href="/terms">Terms & Conditions</a></span>
            </div>
        </header>
    );
}

export default Menu;