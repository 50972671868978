function Mission() {
    return (
        <div className="mission" id="mission">
            <div className="story">
                <h1 className="pink">Our Mission</h1>
                <div className="mission-text">
                    The modern dating scene has become increasingly challenging. <br/>Today's dating apps often lead to fatigue and frustration, 
                    with unequal chances for success and countless options that rarely result in real-life connections.
                    <br/>
                    At SwiftCrush, we believe in giving everyone an equal chance to find meaningful connections. Instead of the typical swipe-based approach, 
                    we offer you <b className="pink">three slots</b> for incoming and outgoing invitations. This means <b className="pink">you can only receive 
                    three invitations to chat at a time</b> and <b className="pink">send three invitations to chat.</b><br/>
                    Our goal is to foster genuine interactions, not just to send out generic, template-like messages.
                </div>
            </div>
        </div>
    );
}

export default Mission;