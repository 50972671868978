import Footer from "./Footer";

function TermsAndConditions() {
    return (
        <div className="termsAndConditions">
            <div class="chevronBack"><a href="/" style={{"textDecoration":"none"}}>‹</a></div>
            <div className="logo-right">
                <a href="/">
                    <img alt="logo" src="/img/heart.png" width="40"></img>
                    <span style={{color: "#99627A"}}>SwiftCrush</span>
                </a>
            </div>
            <div className="story termsStory">
                <h1 className="pink">Terms of Use Agreement</h1>
                <div className="mission-text" style={{color: "black"}}>
                    By accessing our services, you agree to abide by our <b>terms and conditions.</b> <br/>
                    Please read the following terms carefully before proceeding.
                </div>
            </div>
            <div className="containerTerms">
                <div className="box bColor">
                    <span style={{"font-size":"1.4rem"}}>Welcome to <a href="https://swiftcrush.com" style={{textDecoration:"none"}}><b className="pink">SwiftCrush</b></a>, a platform designed to help individuals connect and
                    build meaningful relationships.
                    Before using our services, please ensure that you read our <b>terms and conditions</b> and <a href="/privacy" style={{color:"black"}}>privacy policy </a>
                    carefully and you understand the following statements:</span>
                    <br/><br/>
                    <b>No Guarantee of Matches or Relationships:</b><br/>
                    <a href="https://swiftcrush.com"><b className="pink">SwiftCrush</b> </a> does not guarantee that users will find a match
                    or form a relationship. The success of connections depends on various factors, including user
                    interaction, compatibility, and mutual interests.<br/>

                    <br/><b>User Responsibility:</b><br/>
                    Users are responsible for their own actions and interactions. <a href="https://swiftcrush.com"><b
                    className="pink">SwiftCrush </b></a>
                    encourages users to exercise caution and perform due diligence when interacting with others on
                    the
                    platform. Users should verify the authenticity of other users independently.<br/>

                    <br/><b>Content and Conduct:</b><br/>
                    <a href="https://swiftcrush.com"><b className="pink">SwiftCrush</b> </a> is not responsible for the content posted by users or their conduct.
                    While we strive to maintain a safe environment, we do not pre-screen or endorse user-generated
                    content. Any inappropriate behavior, harassment, or misuse of the platform should be reported to
                    our
                    support team immediately.<br/>

                    <br/><b>Personal Safety:</b><br/>
                    Users should prioritize their personal safety and privacy when interacting with
                    others. Avoid sharing sensitive personal information (such as home addresses, financial details,
                    etc.) with people you do not know well. Always meet in public places for initial meetings.<br/>

                    <br/><b>Third-Party Links and Services:</b><br/>
                        <a href="https://swiftcrush.com"><b className="pink">SwiftCrush</b> </a> may contain links to third-party websites or services. We
                    do not control or endorse these external sites and are not responsible for their content,
                    <a href="/privacy" style={{color:"black"}}>privacy
                    policies</a>, or practices. Use these links at your own risk.<br/>

                    <br/><b>Data Security:</b><br/>
                    While we implement security measures to protect your personal information, we cannot
                    guarantee the absolute security of your data. Users should be aware of the inherent risks of
                    online
                    communication and take steps to protect their information.<br/>


                    <br/><b>Service Changes:</b><br/>
                            We reserves the right to modify, suspend, or discontinue any aspect of the
                    service at any time without notice. This includes the right to alter or update these terms as
                    necessary.<br/>

                    <br/><b>Limitation of Liability:</b><br/>
                                We are not liable for any direct, indirect, incidental, or
                    consequential damages arising out of or related to the use or inability to use the service. This
                    includes damages for loss of data, personal injury, or emotional distress.<br/>


                </div>
                <div className="box ">
                    <ul className="largeT">
                        <li><a href="#section1">Introduction</a></li>
                        <li><a href="#section2">Eligibility</a></li>
                        <li><a href="#section3">Your responsibility</a></li>
                        <li><a href="#section4">Content</a></li>
                        <li><a href="#termination">Termination of your contract with us</a></li>
                        <li><a href="#safety">Safety</a></li>
                        <li><a href="#disclaimer">Disclaimer</a></li>
                        <li><a href="#liability">Liability</a></li>
                        <li><a href="#statement">Summary</a></li>

                        <li><a href="#appendixA">Appendix A</a></li>
                        <li><a href="#appendixB">Appendix B</a></li>
                    </ul>
                </div>
                <div className="box">
                    <img alt="terms" src="/img/terms.png" width="600px"></img>
                </div>
            </div>
            <div id="section1">
                <div className="mission-text-terms">Introduction</div>
                <div className="terms-text">

                    <a href="https://swiftcrush.com" style={{textDecoration:"none"}}><b class="pink">SwiftCrush</b></a> is
                        an online dating platform operated by <b
                            class="pink">BYTECHERRY</b>, a limited company registered in England and Wales. <b>Company number:
                    13367745.</b><br/>
                    We use the following terms: <b>"we," "our," "us," "product," "app," "service," "platform,"
                    "SwiftCrush"</b> to describe the same mobile app and the website associated with it.<br/>
                    <br/> By accessing or using our services, website, or mobile application, you agree to these <b>Terms
                    & Conditions</b>, regardless of your registration or subscription status.<br/>
                    Your access and use are also subject to the other documents available on the website, including
                    the <a href="/privacy" style={{color:"black"}}><b>Privacy Policy</b></a>.<br/>
                    <br/> We reserve the right to modify these terms at any time. Any changes will be posted on this
                    page, and
                    you are responsible for regularly checking this space and any other
                    pages related to these <b>Terms and Conditions.</b><br/>
                    If you do not accept any of these changes, you must stop using the app immediately and request to
                    close your account.
                </div>
            </div>
            <div id="section2">
                <div className="mission-text-terms">Eligibility</div>
                <div className="terms-text">You can only create an account with us when the following criteria are fully met.
                    If you are unable to meet any of them, you are disqualified from creating an account with us.

                    <ul>
                        <li>You are at least 18 years old on the date of registration.</li>
                        <li>You're located in the <b>United Kingdom.</b></li>
                        <li>You have not previously been removed or banned from using the app.</li>
                        <li>You may only utilize our product as an individual. <a href="https://swiftcrush.com" style={{textDecoration:"none"}}><b className="pink">SwiftCrush</b> </a> cannot
                            be utilized to represent a company or any other corporate entity.</li>
                        <li>You are not prohibited by any law from using the app.</li>
                        <li>You have not committed or been convicted of any form of sexually related crime or any other
                            crime involving violence.
                        </li>
                        <li>You are not a registered sexual offender.</li>
                        <li>You don't have any other accounts with us.</li>
                    </ul>
                    For any violations of the following terms, we retain the right to remove or suspend your account
                    without any warning prior to that.
                </div>
            </div>
            <div id="section3">
                <div className="mission-text-terms">Your responsibility</div>
                <div className="terms-text">
                    <ul>
                        <li>By using our services, you agree to adhere to the <b>Terms & Conditions</b> and all other
                            applicable rules, and to keep yourself informed of any future changes.
                        </li>
                        <li>You agree to respect the rights of individuals, comply with relevant laws, intellectual
                            property regulations, and other legal requirements.
                        </li>
                        <li>You pledge to treat other users with respect.</li>
                        <li>Provide accurate information about yourself, including photos and bio, and refrain from
                            falsifying details such as age, name, or profession, or creating misleading impressions that
                            could lead others to misunderstand you.
                        </li>
                        <li>You are responsible for using the service appropriately and must not disrupt or interfere
                            with the network or infrastructure in any way.
                        </li>
                        <li>Engaging in bullying, harassment, stalking, assault, or defamation towards other users is
                            strictly prohibited.
                        </li>
                        <li>Posting illegal or offensive content, particularly content that targets individuals,
                            emotions, religion, or race, is strictly prohibited.
                        </li>
                        <li>Actions intended to mislead, such as impersonation, fraudulent activities, or soliciting
                            money or items from other users, are prohibited.
                        </li>
                        <li>Using the service for political campaigning or any form of influencing others is not
                            allowed, nor is promoting other websites, dating apps, or products.
                        </li>
                        <li>Distribution of information for which you do not have the rights to disclose is
                            prohibited.
                        </li>
                        <li>Copying, distributing, or creating derivative works from content belonging to our members,
                            including images, materials, logos, or any other content subject to intellectual property
                            rights within the app, is strictly prohibited.
                        </li>
                        <li>Using crawlers, robots, automated scripts, or devices to scrape content, manipulate headers,
                            mirror our website or app, decipher, reverse engineer, modify, forge, or compromise our
                            security in any way is strictly forbidden.<br/> Additionally, uploading viruses or using any
                            other software to exploit our product, probe, or scan for vulnerabilities is prohibited.
                        </li>
                    </ul>
                </div>
            </div>
            <div id="section4">
                <div className="mission-text-terms">Content</div>
                <div className="terms-text">
                    We bear no responsibility for the content you post. Therefore, by using our service, you agree to
                    indemnify and hold us harmless from any claims
                    related to it that may arise as a result of your actions.
                    <br/>You are entirely responsible for the content you share with others.

                    By sharing your content, whether in the description or photos,
                    you agree not to violate our <b>Terms and Conditions</b> <br/>or post anything that could
                    potentially
                    expose you or us to legal liability. It is your sole responsibility to ensure you are not violating
                    any laws. You also give us warrant that it is accurate and that you have the rights to publish it.
                    <br/> <br/>
                    It is forbidden to post any personal information, contact details, including but not limited to your
                    address, post code, telephone number,
                    etc. You are also not allowed to post any personal information about other people.
                    <br/><br/>
                    Your content will be visible to a large audience, so make sure you are comfortable with what you
                    share.
                    Keep in mind that your content may be shared with people who do not respect these terms, and we are
                    not responsible for that.
                    <br/>
                    By sharing anything with us, you automatically grant us a license to present this information to
                    other users. You give us the rights to modify, restrict, or delete it without any notice.
                    <br/><br/>
                    We reserve the right to monitor, review, amend, remove, or restrict content without prior notice.
                    However, you agree that we are not obligated to do so automatically; it is at our sole discretion.
                    <br/><br/>
                    We, as a platform, host content from various users, including you. We hold no responsibility for the
                    accuracy of such content.<br/>
                    You should always exercise caution when engaging with other users and should not make any actions,
                    assumptions, or decisions
                    based on presumed accuracy.
                    <br/><br/>
                    You have no rights to other people's content. You are prohibited from copying, modifying,
                    redistributing, or reusing such content
                    in any form, especially for commercial purposes, spam, or harassment.
                    <br/><br/>
                    We reserve the right to limit access to your content for any violations against this document or the
                    law, or when there is a
                    legitimate business need to do so, such as evidence for any legal proceedings against you.
                    <br/>
                    You agree that we may disclose your information if required by law or if we believe it's necessary
                    to comply with the law or protect our rights or other users.
                    This includes investigating any forms of fraud or wrongdoing.
                    <br/><br/>
                    We encourage users to review and report any forms of misconduct in relation to the terms of service
                    and this platform.
                    <br/>
                    Your content may be reported by other users, and we reserve the right to take necessary actions to
                    prevent any violations, whether
                    reviewed by us or reported by others.
                    <br/><br/>
                    <b>We aim to review any violation of these terms of service within 24 hours. Upon such a process,
                        we can either remove the content or block your account, depending on the severity of the
                        violation.</b>
                            <br/><br/>
                            We, as a platform, own all content or are licensed to use it, including text, trademarks,
                            graphic
                            design, know-how, or any other
                            forms of artwork used within the service. We grant you the right to use it solely for the
                            purpose of
                            using the app.
                            <br/>
                            You have no rights to reuse, modify, link, copy, or make any amendments or repost it
                            elsewhere
                            outside of the service.
                            <br/><br/>
                            By posting anything within the service, you declare that you do not violate any laws or
                            intellectual
                            property rights in any way,
                            shape, or form, and that you have the rights to use the content.
                            <br/><br/>

                            <a href="https://swiftcrush.com" style={{textDecoration: "none"}}><b
                                className="pink">SwiftCrush</b>
                            </a> is an online platform and is widely available on the internet.
                            We have no obligations to take any actions for any misuse of your content outside of our
                            service by
                            any other users, businesses,
                            or individuals.
                            <br/>
                            However, you are granting us the right
                            to perform such actions on your behalf at our discretion. This means we can request the
                            removal of
                            your content without your explicit permission
                            from any other website or service outside of the platform in case of such a violation.
                            <br/><br/>
                            By posting your content with us, you remain the sole owner, but you give us a royalty-free
                            license
                            to use, modify, present to other
                            users, publish on our website, and use in marketing campaigns within our platform. <br/>
                            For example, your
                            content may be used in a success
                            story section, as a screenshot from your profile, or by any other means to promote our
                            platform.
                </div>
            </div>

            <div id="termination">
                <div className="mission-text-terms">Delete your account
                    / Terminate your contract with us
                </div>
                <div className="terms-text">
                    Users who wish to delete their account must submit a formal request through our designated process
                    described <a href="/deleteAccount" style={{"textDecoration": "none", "font-weight": "bold"}}
                                 className="pink">here</a>.

                    <br/><br/>
                    Upon receiving a deletion request, we will verify the user's identity to ensure the security of the
                    account.
                    <br/> <br/>

                    Once verified, the account will be scheduled for deletion.

                </div>

            </div>
            <div id="safety">
                <div className="mission-text-terms">Safety</div>
                <div className="terms-text">
                    <a href="https://swiftcrush.com" style={{textDecoration:"none"}}><b className="pink">SwiftCrush</b> </a> intends to be a safe place. We ensure timely responses to any
                    violations or misconduct,
                    especially toward other users.
                    However, we do not perform background checks on our users, nor do we verify the authenticity of
                    information presented or shared with us or other users of the platform.<br/><br/>
                    Additionally, we do not conduct identification or criminal record checks unless there is a valid reason.
                    <br/><br/>
                    Our terms and conditions strictly forbid anyone convicted of crimes related to violence or sex
                    offenses from using our service.<br/>
                    We reserve the right to perform such checks or screenings at our discretion to make the necessary safety measures or if we believe there is a
                    legitimate reason to do so. By registering with us, you grant us this right.
                </div>
            </div>
            <div id="disclaimer">
                <div className="mission-text-terms">Disclaimer</div>
                <div className="terms-text">
                    <a href="https://swiftcrush.com" style={{textDecoration:"none"}}><b className="pink">SwiftCrush</b> </a> offers its services on an <b>"as is"</b> and <b>"as
                    available"</b> basis. <br/><br/>
                    <a href="https://swiftcrush.com" style={{textDecoration:"none"}}><b className="pink">SwiftCrush</b> </a> disclaims all warranties, whether express, implied,
                    statutory, or otherwise, regarding
                    our services, including all content within.
                    This encompasses implied warranties of satisfactory quality, merchantability, fitness for a
                    particular purpose, or non-infringement.<br/><br/>

                    As a service provider, we do not make any guarantees for:<br/>
                    <ol>
                        <li>Uninterrupted, secure, or error-free provision of our services</li>
                        <li>Identification or correction of any defects or errors in our services</li>
                        <li>Accuracy, completeness, currency, or suitability of any content or information
                            obtained through our services.
                        </li>
                    </ol>

                    Additionally, <a href="https://swiftcrush.com" style={{textDecoration:"none"}}><b className="pink">SwiftCrush</b> </a> makes no assurances about the volume of active
                    users, their willingness
                    or
                    ability to engage with you, or the
                    ultimate compatibility or conduct of users you may encounter through our platform.
                    <br/><br/>
                    <a href="https://swiftcrush.com"><b className="pink">SwiftCrush</b> </a> assumes no liability for any content posted, transmitted,
                    received, or acted upon by
                    you, other users, or third parties through our services. We do not assume responsibility for the
                    identity, intentions, legitimacy, or
                    truthfulness of any users you engage with through <a href="https://swiftcrush.com"><b
                    className="pink">SwiftCrush. </b></a>
                    Any material obtained through our services is accessed at your own discretion and risk.
                    <br/><br/>
                    We bear no responsibility for any damage to your computer hardware,
                    software, or other
                    technology, including but not limited to damage
                    resulting from security breaches, viruses, bugs, tampering, hacking, fraud, errors, omissions,
                    interruptions, defects, delays in operation or transmission,
                    computer line or network failures, or any other technical disruptions or malfunctions.
                </div>
            </div>
            <div id="liability">
                <div className="mission-text-terms">Liability</div>
                <div className="terms-text">

                    <a href="https://swiftcrush.com" style={{textDecoration:"none"}}><b className="pink">SwiftCrush</b></a>, its affiliates, employees, licensors, or service providers shall
                    not be liable for any
                    indirect,
                    consequential, exemplary, incidental, special, punitive, fixed, or enhanced damages, including,
                    without limitation,
                    loss of profits, whether incurred directly or indirectly, or any loss of data, use, goodwill, or
                    other intangible losses, arising from:<br/>

                    <ol>
                        <li>Your utilization of or inability to access the services.</li>
                        <li>The behavior or content generated by any users.</li>
                        <li>Any unauthorized entry, use, or modification of your content.
                        </li>
                    </ol>

                    Your claims may not be consolidated over a period of 36 months, irrespective of the legal
                    proceedings pursued against us,
                    the grounds of liability, or the type of breach of rights or obligations, pertaining to all
                    occurrences, usage of the service,
                    or this agreement.

                </div>
            </div>
            <div id="statement">
                <div className="mission-text-terms">Summary</div>
                <div className="terms-text">
                    Our <b>Terms of Service</b>, <a href="/privacy" style={{color:"black"}}><b>Privacy Policy</b></a>, and all other associated documents on our website or within
                    the mobile app are legally
                    binding in their current form and supersede any prior agreements, whether written or oral. Should
                    any statement within these
                    documents be deemed invalid or unenforceable due to illegality or human error, the remaining
                    provisions shall remain in full force
                    to the extent permitted by applicable law.<br/><br/>

                    Any failure on our part to exercise rights outlined in these terms does not constitute a waiver of
                    said rights, and they remain
                    enforceable regardless of our actions to enforce them at any given time.
                    <br/><br/>
                    Your account is non-transferable and will be terminated upon your death, unless legal or reasonable
                    grounds exist to maintain it.
                    Any rights or licenses granted for the use of our product are also non-transferable and do not bind
                    us in any form.
                    <br/><br/>
                    By utilizing our service, you fully acknowledge and agree to adhere to these terms, whether accessed
                    through our mobile app, website,
                    or any other associated means. Failure to accept these terms necessitates immediate cessation of
                    product usage and termination of your account.
                    Our terms and services remain binding at all times, regardless of service availability, maintenance
                    periods, or any errors causing malfunctions.
                    <br/><br/>
                    By <b>accepting our terms</b>, you agree to indemnify us from any claims arising directly or indirectly
                    from your actions, behavior, content you post,
                    interactions with other users, or any other associated activities, whether intentional or
                    unintentional, that may cause harm to us,
                    other users, or our business where permitted by the law.
                </div>
            </div>
            <div id="appendixA">
                <div className="mission-text-terms">Appendix A - List of strictly prohibited actions</div>
                <div className="terms-text">

                    Below is a list of content that is strictly prohibited from being shared or distributed in any form
                    via our service.<br/>
                    <a href="https://swiftcrush.com" style={{textDecoration:"none"}}><b className="pink">SwiftCrush</b> </a> is committed to safeguarding your
                    rights and ensuring that it remains a safe place to stay.
                    We do not tolerate any forms of abuse towards our members or violation of the following policy.

                    <br/><br/>

                    Any content intended to upset, harass, or embarrass others, whether it's obscene, violent,
                    pornographic, or degrading to human dignity,
                    will be removed without warning.
                    <br/>
                    Moreover, we reserve the right to ban your account and take legal
                    action if necessary to prevent or
                    protect the rights of other users.
                    <br/><br/>
                    Sexism, racism, promoting violence, encouraging illegal activities, terrorism, hate speech,
                    defamation, spreading false information,
                    or discrimination in any form is strictly prohibited.
                    <br/>
                    Promotion of commercial activities in any form is not allowed.
                    <br/><br/>
                    Content containing viruses, junk, spam, worms, spyware, or any other malicious software is
                    prohibited.
                    <br/>
                    Any content that may harm our reputation, defame our company or product, or advocate the misuse or
                    exploitation of our service is strictly prohibited.

                </div>
            </div>
            <div id="appendixB">
                <div className="mission-text-terms">Appendix B - Dispute resolution procedure</div>
                <div className="terms-text">
                    In the unlikely event of a dispute, parties agree to proceed as follows, except where prohibited by
                    law:
                    <br/><br/>
                    <b>Informal Dispute Resolution:</b>
                    <br/>
                    <ol>
                        <li> If you are dissatisfied with our service, you must first raise your concerns in writing,
                            either
                            via
                            email to our customer service or to our registered
                            address located in the <a href="/privacy#section1" style={{color:"black"}}><b>'Who We Are'</b></a> section as part of our informal dispute resolution
                                process.
                        </li>
                        <li> We
                            aim to respond to your claims within <b>60 working days </b>
                                from the date of delivery unless both parties agree to extend this period while the
                                dispute is in motion.
                        </li>
                        <li>When making informal disputes, you agree to provide the broadest possible context of your
                            claims, including detailed information about you and your account, screenshots, a description of the issue, or
                            any other form detailing the problem so
                            that the nature of the issue can be established without any doubts. Additionally, specify
                            the relief you are seeking or any remedy for the loss.
                            To be effective, your claims must be personally signed.
                        </li>
                        <li>
                            You agree to negotiate the resolution in good faith and in proportion to the size or scale
                            of the loss. The informal process should aim to resolve the issue; as part of it, we may request a conference
                            call or in-person participation to discuss the issue.
                        </li>
                        <li>
                            Any settlements or offers made during this informal process are final, binding,
                            confidential, and shall not be subject to any further proceedings, whether in court, arbitration, or any other legal
                            form.
                        </li>
                        <li>
                            Failure to participate in this process is a breach of our agreement, and no claims in court
                            or arbitration shall be made before completing this process.
                        </li>
                    </ol>

                    <b>Arbitration:</b>
                    <br/>
                    <ol>
                        <li>
                            For disputes that cannot be resolved through our dispute process, where both parties agree
                            or if
                            your concerns cannot be addressed within the outlined period,
                            you shall seek resolution through arbitration.
                        </li>
                        <li>Both parties choose <a href="https://www.cdrl.org.uk/" style={{color:"black"}}><b>Consumer Dispute Resolution Ltd (CDRL)</b></a> as the arbitration provider
                            <b> (https://www.cdrl.org.uk/)</b>
                        </li>
                        <li>
                            Arbitration shall be conducted within a reasonable amount of time, not exceeding the
                            timeframe outlined on the CDRL website.
                        </li>
                    </ol>

                    <b>Small Claim Court:</b>
                    <br/>
                    <ol>
                        <li>
                            Disputes that cannot be resolved via the arbitration process shall be brought to small
                            claims
                            court.
                        </li>
                        <li>
                            If a small claims court specifically determines that it lacks jurisdiction to hear the
                            dispute, you and <a href="https://swiftcrush.com" style={{textDecoration:"none"}}><b className="pink" >SwiftCrush</b></a> agree to
                            arbitrate the dispute under the terms of this Agreement.
                        </li>
                    </ol>


                    <b>Governing Law and Jurisdiction:</b><br/>
                    <ol>
                        <li>The governing law is the law of the <b>United Kingdom</b>, and disputes shall be subject to the
                            jurisdiction of the country and within its boundaries.</li>
                        <li>
                            Disputes should be resolved in accordance with the laws applicable to the place of the
                            company's
                            residency.
                        </li>
                        <li> These rules apply to any claims that cannot be arbitrated or resolved in the first step
                            by
                            adhering
                            to our Informal Dispute Resolution process.</li>
                    </ol>
                </div>
            </div>
            <Footer/>
        </div>

)}

export default TermsAndConditions;