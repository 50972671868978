import Layout from "./Layout";
import Menu from "./Menu";
import MainPage from "./MainPage";
import Mission from "./Mission";
import HowItWorks from "./HowItWorks";
import Footer from "./Footer";
import WhatAreYouWaitingFor from "./WhatAreYouWaitingFor";
import Social from "./SocialMedia";

function Home() {
    return (
        <Layout>
            <Menu/>
            <MainPage/>
            <Mission/>
            <HowItWorks/>
            <WhatAreYouWaitingFor/>
            <Social/>

            <Footer/>
        </Layout>
    );
}

export default Home;