function Footer() {
    return (
        <footer>
            <p><b className="pink">SwiftCrush</b>® is a part of <a href="https://bytecherry.co.uk/" style={{"textDecoration":"none"}}><b className="pink">ByteCherry
                LTD</b></a> a
            limited company registered in England and Wales. Company number: <b>13367745.</b></p>
        </footer>
    );
}

export default Footer;