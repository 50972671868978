function HowItWorks() {
    return (
        <div className="howItWorks" id="howitworks">
            <div class="story">
                <h1 className="pink">How it Works</h1>
                <div className="mission-text">
                    <b className="pink">SwiftCrush</b> stands out from other dating apps with its unique messaging system. <br/>Instead of allowing users to send countless random messages, <b className="pink">SwiftCrush</b> uses an invitation mechanism.<br/>
                    <b className="pink">Users can send an invitation to chat when they wish to interact with someone.</b><br/> Each user has <b className="pink">three invitation slots</b> for sending and receiving invitations.<br/>
                    <b className="pink">Once an invitation is accepted, users can start chatting.</b><br/>
                    If an invitation is rejected or the conversation ends, the slot becomes available again. This system encourages users to focus on meaningful interactions with a small group of people, enhancing the quality of their connections. <br/>
                    By limiting the number of invitations, SwiftCrush ensures that each interaction is intentional and valued.
                </div>
            </div>

            <div className="video-row">

                <div className="video-container">
                    <iframe title="Welcome to SwiftCrush" width="600" height="400"
                            src="https://www.youtube.com/embed/pIGMm-Kp83w"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                    <div className="videDescription">Welcome to SwiftCrush</div>
                </div>
                <div className="video-container">
                    <iframe title="How to use Invitations" width="600" height="400"
                            src="https://www.youtube.com/embed/PJxDxxsUbY4"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    <div className="videDescription">How to use Invitations</div>
                </div>
                <div className="video-container">
                    <iframe title="What is SwiftCrush" width="600" height="400"
                            src="https://www.youtube.com/embed/MLqbzmS9h6w" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                    <div className="videDescription">What is SwiftCrush</div>
                </div>
            </div>
        </div>
    );
}

export default HowItWorks;
