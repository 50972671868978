import Footer from "./Footer";

function DeleteAccount() {
    return (
        <div className="termsAndConditions">
            <div className="chevronBack"><a href="/" style={{"textDecoration": "none"}}>‹</a></div>
            <div className="logo-right">
                <a href="/">
                    <img alt="logo" src="/img/heart.png" width="40"></img>
                    <span style={{color: "#99627A"}}>SwiftCrush</span>
                </a>
            </div>
            <div className="story termsStory">
                <h1 className="pink">Delete your account</h1>
                <div className="mission-text" style={{color: "black"}}>
                    If you wish to delete your account and terminate your contract with us, you can follow these steps:
                </div>
            </div>

            <div id="termination">

                <div className="terms-text" style={{"marginTop": 0, "paddingTop": 5}}>

                    <ol>
                        <li>Log in to your profile.</li>
                        <li>Click on the <b>Cog</b> icon in the upper right corner of the screen.</li>
                        <li>Select <b>"Delete Account"</b> at the bottom of the screen.</li>
                    </ol>


                    Alternatively, you can send us a direct request to do so. In such a case, we may ask you to confirm
                    your identity by,
                    for example, sending us a copy of your ID.<br/><br/>

                    We will delete your account in accordance with our terms and privacy policy. We also reserve the
                    right to terminate or
                    suspend your account in case of any violations of our terms and conditions.
                    <br/>
                    Please note that you are responsible for any actions taken prior to the termination of your account.
                    This means that you
                    might still be responsible for any violations towards other users, our platform, or applicable law.
                    <br/><br/>You may still be liable
                    for any offenses or wrongdoings; for example, we may hold you responsible for any forms of
                    harassment towards other members
                    or any damage caused by your past actions, whether such violations have been revealed at the date of
                    your termination or in
                    the future, for example, through ongoing investigations into your conduct.

                    <br/><br/>
                    This process may take up to <b>30 business days</b>.
                    <br/><br/>

                    For further assistance or inquiries regarding account termination, users can contact our support
                    team at: <a href="mailto:gdpr@swiftcrush.com" className="pink"
                                style={{"textDecoration": "none"}}><b>gdpr@swiftcrush.com</b></a>.
                </div>

            </div>
            <div id="retention">
                <div className="mission-text-terms">Data Retention and Deletion</div>
                <div className="terms-text">
                    All personal data associated with the account will be permanently deleted from our systems after the
                    termination process is complete.
                    Users acknowledge that certain data may be retained for legal, regulatory, or security purposes for
                    a specified period after account deletion.

                </div>
            </div>
            <div id="retention">
                <div className="mission-text-terms">Contract Termination</div>
                <div className="terms-text">
                    Termination of the account will also terminate any active contracts or subscriptions associated with
                    the account.
                    <br/>
                    Users are responsible for fulfilling any outstanding obligations, such as payments or service
                    commitments, prior to account deletion.
                </div>
            </div>
            <div id="actions">
                <div className="mission-text-terms">Non-Recoverable Actions</div>
                <div className="terms-text">
                    Once an account is deleted, the action cannot be reversed. Users will not be able to recover any
                    data or information associated with the deleted account.

                </div>
            </div>
            <div id="balance">
                <div className="mission-text-terms">Outstanding Balances</div>
                <div className="terms-text">
                    Any outstanding balances or fees must be settled before the account can be successfully terminated.
                    Failure to do so may result in the delay or denial of the account deletion request.
                </div>
            </div>
            <div id="notification">
                <div className="mission-text-terms">Notification</div>
                <div className="terms-text">
                    Users will receive a confirmation email once the account has been successfully deleted.
                </div>
            </div>
            <div id="changes">
                <div className="mission-text-terms">Changes to Termination Policy</div>
                <div className="terms-text">
                    We reserve the right to modify these terms and conditions at any time. Users will be notified of any
                    significant changes.
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default DeleteAccount;